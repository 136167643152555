body {
  margin: 0;
  font-family: Montserrat;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  cursor: url('https://www.isolite.co.jp/wp-content/uploads/2017/03/small-black-dot-png-2.png'), url('https://www.isolite.co.jp/wp-content/uploads/2017/03/small-black-dot-png-2.png'), url('https://www.isolite.co.jp/wp-content/uploads/2017/03/small-black-dot-png-2.png'), auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'MontserratItalic';
  src: local('MontserratItalic'), url(./fonts/Montserrat-Italic-VariableFont_wght.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'), url(./fonts/Montserrat-VariableFont_wght.ttf) format('truetype');
}

