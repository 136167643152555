.burgerMenuIcon {
  height: 40px !important;
  width: 40px !important;
  float: right !important;
  margin-right: 15rem !important;
}
.CatIcon {
  height: 40px !important;
  width: 40px !important;
  float: left !important;
  margin-left: 30rem !important;
}
.sunIcon {
  color: #e0e0ea;
  height: 40px !important;
  width: 40px !important;
  float: right !important ;
  margin-right: 1rem !important;
}
.moonIcon {
  color: #1d1e20;
  height: 40px !important;
  width: 40px !important;
  float: right !important ;
  margin-right: 1rem !important;
}

@media (max-width: 1200px) {
  .burgerMenuIcon {
    height: 40px !important;
    width: 40px !important;
    float: right !important;
    margin-right: 3rem !important;
  }
  .CatIcon {
    height: 40px !important;
    width: 40px !important;
    float: left !important;
    margin-left: 1rem !important;
  }
  .sunIcon {
    color: #e0e0ea !important;
    height: 40px !important;
    width: 40px !important;
    float: right !important;
    margin-right: 1rem !important;
  }
  .moonIcon {
    color: #1d1e20;
    height: 40px !important;
    width: 40px !important;
    float: right !important ;
    margin-right: 1rem !important;
  }
}
