.main-container{
    /* border: #e0e0ea solid 1px; */
    margin-top: 10vh;
    padding: 4rem;
}
.main-hi-text{
    float: left;
    font-size: 1.5rem;
    font-weight: 500;
    text-align: left;
}

.main-name-text{
    float: left;
    font-size: 3.5rem;
    font-weight: 800;
    text-align: left;
}
.main-desc-text{
   
    float: left;
    font-size: 2rem;
    font-weight: 500;
    color: #e0e0ea79;
    text-align: left;
}
.main-aboutme-text{
    font-family: MontserratItalic;
    float: left;
    font-size: 1.5rem;
    font-weight: 500;
    color: #e0e0ea79;
   
}

.main-projects-text{
    cursor: pointer;
    font-family: MontserratItalic;
    float: left;
    font-size: 1.5rem;
    font-weight: 500;
    color: #e0e0ea79;
    text-align: left;
}

@media (max-width: 700px) {
    .main-container{
        /* border: #e0e0ea solid 1px; */
        margin-top: 5vh;
        padding: 2rem;
    }
    .main-hi-text{
        float: left;
        font-size: 1rem;
        font-weight: 500; 
        text-align: left;
    }
    
    .main-name-text{
        float: left;
        font-size: 3rem;
        font-weight: 800;
        color: #e0e0ea;
        text-align: left;
    }
    .main-desc-text{
        float: left;
        font-size: 2rem;
        font-weight: 500;
        color: #e0e0ea79;
        text-align: left;
    }

  }